/* Common Imports */

import React from "react";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";
import BeegruButton from "../../../common-components/buttons/BeegruButton";

const PropertyCardConfigAndContact = ({ config }: { config: string | null | undefined }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%",
					margin: "0rem 0rem 0rem 0rem",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						alignItems: "flex-start",
						height: "100%",
						width: "46%",
					}}
				>
					<Typography variant="body1">{config}</Typography>
					<Typography
						variant="body2"
						color={theme.palette.text.secondary}
					>
						Configuration
					</Typography>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "52%",
						padding: "0rem 0rem 0rem 0.75rem",
					}}
				>
					<BeegruButton
						variant="outlined"
						color="primary"
						sx={{
							fontSize: "0.875rem",
							lineHeight: "1rem",
							letterSpacing: "0.025rem",
							fontWeight: 400,
							width: "100%",
							height: "100%",
							padding: "0.5rem 0.5rem 0.5rem 0.5rem",
						}}
						onClick={(event: any) => {}}
					>
						Get a callback
					</BeegruButton>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PropertyCardConfigAndContact;
