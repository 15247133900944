/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Area } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Library Imports */

import prepareAreaString from "../../../../lib/PrepareAreaString";

/* Styled Components */

const Heading2 = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const GridCardCarpetAndStatus = ({ area, areaType }: { area: Area; areaType: string }) => {
	const theme = useTheme();

	return (
		<Heading2>
			<Typography variant="body1">{prepareAreaString(area, areaType)}</Typography>
			<Typography
				variant="body2"
				color={theme.palette.text.secondary}
			>
				Area
			</Typography>
		</Heading2>
	);
};

export default GridCardCarpetAndStatus;
