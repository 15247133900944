/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";
import CapitalizeFirstLetter from "../../../../lib/CapitalizeFirstLetter";

/* Styled Components */

const Heading = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 1rem",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const GridCardStatus = ({
	property_status,
	possession_date,
	transaction_type,
}: {
	property_status: Array<string>;
	possession_date: string;
	transaction_type: string;
}) => {
	const theme = useTheme();

	return (
		<Heading>
			<Typography variant="body1">
				{property_status.includes("Under construction") ||
				property_status.includes("under_construction") ||
				property_status.includes("Select date") ||
				property_status.includes("select_date")
					? new Date(possession_date).toLocaleDateString("en-IN")
					: property_status.map((element: string) =>
							element.length > 13
								? CapitalizeFirstLetter(element.replace(/_/g, " ")).substring(0, 13) + "..."
								: CapitalizeFirstLetter(element.replace(/_/g, " ")),
						)}
			</Typography>
			<Typography
				variant="body2"
				color={theme.palette.text.secondary}
			>
				{property_status.includes("Under construction") ||
				property_status.includes("under_construction") ||
				property_status.includes("Select date") ||
				property_status.includes("select_date")
					? transaction_type === "rent"
						? "Available from"
						: "Possession date"
					: "Property status"}
			</Typography>
		</Heading>
	);
};

export default GridCardStatus;
