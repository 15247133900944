/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { setProfileContextThunk, FetchMoreBusinessProfilesThunk } from "../../../../redux-magic/thunks";

/* Component Imports */

import {
	Typography,
	useTheme,
	Link,
	Radio,
	RadioGroup,
	FormControlLabel,
	SvgIcon,
	Switch,
	CircularProgress,
	Tooltip,
	// ClickAwayListener,
	Snackbar,
	Alert,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CreateBusinessModal from "../../../modals/BusinessProfileModals/CreateBusinessProfileModal";
import ColorModeContext from "../../contexts/colorModeContext";

/* Icon Imports */

import {
	Logout,
	SettingsOutlined,
	Add,
	LiveHelpOutlined,
	ExpandMore,
	OpenInNew,
	ManageAccountsOutlined,
	LightMode,
	DarkMode,
	InfoOutlined,
} from "@mui/icons-material";
import PremiumBadge from "../../../../public/images/badges/premium.svg";
import VerifiedPremiumBadge from "../../../../public/images/badges/verified_premium.svg";
import Points from "../../../../public/images/icons/dev-profile/points.svg";
import Initials from "../../../cards/Initials";

/* Authentication Imports */

import { signOut } from "next-auth/react";

/* Routing Imports */

import { useRouter } from "next/router";

/* Styled Components */

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 0rem 0rem 0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.9rem",
	fontWeight: 400,
}));

const Text = styled(Typography)(({ theme }) => ({
	width: "calc(100% - 2rem)",
	fontSize: "0.875rem",
	fontWeight: 400,
	lineHeight: "1.5rem",
}));

const Links = styled(Link)(({ theme }) => ({
	fontSize: "0.8rem",
	fontWeight: 400,
	lineHeight: "1.5rem",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000000",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	width: "3rem",
	height: "3rem",
	borderRadius: "8px",
	objectFit: "cover",
}));

const LoadingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem",
}));

const ToolTipDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

// const UnordredList = styled("ul")(({ theme }) => ({
//   margin: 0,
//   paddingLeft: "1.5rem",
// }));

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// "&:not(:last-child)": {
	//   borderBottom: 0,
	// },
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={
			<ExpandMore
				sx={{
					fontSize: "1.5rem",
					margin: "0rem 0rem 0rem 0rem",
					color: "#F6802A",
				}}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	border: "none",
	minHeight: "auto",
	background: theme.palette.background.paper,
	flexDirection: "row",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(-180deg)",
	},
	"& .MuiAccordionSummary-content": {
		margin: "0rem 0rem 0rem 0.3rem",
	},
	"& .MuiAccordionSummary-content p": {
		fontSize: "0.875rem",
		fontWeight: 400,
		lineHeight: "1.5rem",
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: "0rem 0rem 0.5rem 1rem",
	fontSize: "0.9rem",
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	width: "100%",
	marginRight: "0rem",
	"& .MuiRadio-root": {
		width: "1rem",
		height: "1rem",
		margin: "0.4rem 0.6rem 0.4rem 0rem",
	},
}));

const ShowMore = styled(Typography)(({ theme }) => ({
	margin: "0rem 0rem 0rem 1rem",
	width: "100%",
	fontWeight: "400",
	fontSize: "0.8rem",
	lineHeight: "1.625rem",
	color: theme.palette.primary.main,
	cursor: "pointer",
}));

const TypeWithNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const TypeWithNameLink = styled(Link)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000000",
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const NameBadgeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: "0.25rem",
	alignItems: "center",
	width: "100%",
	fontWeight: "400",
	fontSize: "0.8rem",
	color: theme.palette.mode == "dark" ? "#D3D3D3" : "#36454F",
}));

const PointsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const RadioWithLink = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const SubDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.7rem",
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	"& .MuiSwitch-switchBase": {
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#FFFFFF",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					theme.palette.primary.main,
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.secondary.dark,
			},
		},
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: theme.palette.background.paper,
		width: 32,
		height: 32,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: "12px",
		boxShadow: "none",
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				theme.palette.primary.main,
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#AAB4BE",
		borderRadius: "16px",
	},
}));

const UserContent = ({
	session,
	dispatch,
	profilePicture,
	profile_context,
	new_business_profile_url,
	redux_session_updated,
}: {
	session: any;
	dispatch: Function;
	profilePicture: string;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	redux_session_updated?: boolean;
}) => {
	const router = useRouter();
	const theme = useTheme();
	const signIn = () => {
		window.location.href = "/auth/login?callbackUrl=" + encodeURIComponent(window.location.href);
	};
	const [open, setOpen] = React.useState(false);
	const pathname = React.useRef(router).current;

	React.useEffect(() => {
		if (pathname.query.create_business_profile === "true" && redux_session_updated === true) {
			redux_session_updated === true && session && session.authorized ? setOpen(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);
	React.useEffect(() => {
		if (pathname.query.create_business_profile === "true" && redux_session_updated === true && open === false) {
			router.push("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);
	const [loading, setLoading] = React.useState(false);
	const [openProfileContextSnackbar, setOpenProfileContextSnackbar] = React.useState(false);
	const [expanded, setExpanded] = React.useState<boolean>(true);
	const [showMore, setShowMore] = React.useState<Boolean>(false);

	// const [countrySelect, setcountrySelect] = React.useState("india");
	// const [langaugeSelect, setLangaugeSelect] = React.useState("");
	// const [currency, setCurrency] = React.useState("");

	let businessProfiles: Array<any> = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages
					: []
				: []
			: []
		: [];

	let businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const session_user = session ? (session.user ? session.user.dbUser : "") : "";

	const [radio, setRadio] = React.useState(
		profile_context && profile_context.is_business_profile
			? profile_context && profile_context.business_profile_id
			: profile_context && profile_context.user_id,
	);

	/* Create new business profile modal functions */

	const handleOpenBusinessProfile = () => {
		setOpen(!open);
	};

	const handleCloseBusinessProfile = () => {
		setOpen(false);
	};

	/* Change profile context function */

	const onChangeProfileContext = async (id: string) => {
		setLoading(true);
		await dispatch(
			setProfileContextThunk({
				is_business_profile: id === session_user._id ? false : true,
				user_id: session_user ? session_user._id : "",
				business_profile_id: id,
				page_type:
					id === session_user._id ? "user" : businessProfiles.find((item: any) => item.page_id === id).page_type,
				user_kyc_status: session.user.dbUser.kyc_info.kyc_status,
				user_subscription_information: session.user.dbUser.subscription_information,
				remember_choice: true,
			}),
		);
		setLoading(false);
	};

	/* Show more business profiles */

	const handleClickShowMoreBusinessProfiles = async () => {
		setLoading(true);
		await dispatch(
			FetchMoreBusinessProfilesThunk({
				user_id: session_user ? session_user._id : "",
				pages: businessProfiles.map((page: any) => {
					return { page_id: page.page_id };
				}),
			}),
		);
		setLoading(false);
	};

	const typeOfProfile = businessProfiles
		? businessProfiles.find((item: any) => item.page_id === radio) &&
			businessProfiles.find((item: any) => item.page_id === radio).page_type
		: "";

	const typeOfProfileSlug = businessProfiles
		? businessProfiles.find((item: any) => item.page_id === radio) &&
			businessProfiles.find((item: any) => item.page_id === radio).page_slug
		: "";

	const userName =
		session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: session.user.dbUser.phone;

	const InitialsUserName =
		session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: "Beegru";

	React.useEffect(() => {
		setRadio(
			profile_context && profile_context.is_business_profile
				? profile_context && profile_context.business_profile_id
				: profile_context && profile_context.user_id,
		);
	}, [profile_context]);

	/* Business profile types */

	let bp_select =
		profile_context && profile_context.is_business_profile
			? businessProfiles.find((item: any) => item.page_id === radio) &&
				businessProfiles.find((item: any) => item.page_id === radio)
			: "user";

	return (
		<React.Fragment>
			{/* Profile context snackbar */}

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openProfileContextSnackbar}
				autoHideDuration={3000}
				onClose={() => {
					setOpenProfileContextSnackbar(false);
				}}
				sx={{
					borderRadius: "0.5rem",
				}}
			>
				<Alert
					onClose={() => {
						setOpenProfileContextSnackbar(false);
					}}
					severity="success"
					sx={{ width: "100%" }}
				>
					{"Switched to " +
						(profile_context?.is_business_profile
							? businessProfiles.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_title
							: userName)}
				</Alert>
			</Snackbar>

			{/* Create Business Profile Modal */}

			<CreateBusinessModal
				open={open}
				onClose={handleCloseBusinessProfile}
				session={session}
				dispatch={dispatch}
				new_business_profile_url={new_business_profile_url}
			/>

			{/* User Profile */}

			<Links
				href={
					profile_context && profile_context.is_business_profile
						? "/" + typeOfProfile + "s/" + typeOfProfileSlug
						: "/users/" +
							(session
								? session.user
									? session.user.dbUser
										? session.user.dbUser.slug && session.user.dbUser.slug.length > 0
											? session.user.dbUser.slug
											: session.user.dbUser._id
												? session.user.dbUser._id
												: ""
										: ""
									: ""
								: "")
				}
				rel="noopener"
				target="_blank"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						borderBottom: "1px solid #E5E5E5",
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: "1rem",
					}}
				>
					{!profilePicture.includes("/images/icons/Avatar.svg") ? (
						<ProfilePicture
							src={`/profile-picture/${profilePicture}`}
							alt="user"
							referrerPolicy="no-referrer"
						/>
					) : (
						<Initials
							title={
								profile_context
									? profile_context.is_business_profile
										? profile_context.page_title
										: InitialsUserName
									: ""
							}
							profiledropdown={true}
							header={false}
							comment={false}
							postcard={false}
							businessprofile={false}
						/>
					)}
					<TypeWithNameContainer>
						<NameBadgeContainer>
							<Heading variant="h6">
								{profile_context
									? profile_context.is_business_profile
										? profile_context.page_title
										: session.user.dbUser.first_name
											? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
											: session.user.dbUser.phone
									: ""}
							</Heading>

							{profile_context ? (
								profile_context.kyc_info?.kyc_status !== "kyc_completed" &&
								profile_context.subscription_information &&
								profile_context.subscription_information.active ? (
									<Tooltip title="Premium">
										<ToolTipDiv>
											<SvgIcon
												component={PremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "0.95rem",
													height: "0.95rem",
												}}
											/>
										</ToolTipDiv>
									</Tooltip>
								) : profile_context.kyc_info?.kyc_status === "kyc_completed" &&
								  profile_context.subscription_information &&
								  profile_context.subscription_information.active ? (
									<Tooltip title="Verified Premium">
										<ToolTipDiv>
											<SvgIcon
												component={VerifiedPremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "0.95rem",
													height: "0.95rem",
												}}
											/>
										</ToolTipDiv>
									</Tooltip>
								) : null
							) : null}
						</NameBadgeContainer>
						<TypeTypo>
							{profile_context
								? profile_context.page_type.charAt(0).toUpperCase() + profile_context.page_type.slice(1)
								: ""}
						</TypeTypo>
					</TypeWithNameContainer>
				</ContentContainer>
			</Links>

			{/* Beegru points */}

			<ContentContainer
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "1rem 1rem 0rem 1rem",
					gap: "1rem",
				}}
			>
				{/* Total Beegru Points */}

				<PointsContainer>
					<SubDiv sx={{ alignItems: "flex-start" }}>
						<Links
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.7rem",
							}}
							href={
								"/users/" +
								(session
									? session.user
										? session.user.dbUser
											? session.user.dbUser.slug && session.user.dbUser.slug.length > 0
												? session.user.dbUser.slug
												: session.user.dbUser._id
													? session.user.dbUser._id
													: ""
											: ""
										: ""
									: "") +
								"/account-settings"
							}
							rel="noopener"
							target="_blank"
							referrerPolicy="no-referrer"
						>
							<SvgIcon
								component={Points}
								inheritViewBox={false}
								viewBox="0 0 42 42"
								width={24}
								height={24}
								sx={{
									width: "1.125rem",
									height: "1.125rem",
								}}
							/>
							<Text sx={{ width: "100%", fontSize: "1.25rem" }}>
								{session.user.dbUser.beegru_points ? session.user.dbUser.beegru_points.toFixed(1) : 0}
							</Text>
						</Links>

						<Tooltip title="This includes your (Purchased Beegru Points + Earned Beegru Points)">
							<InfoOutlined sx={{ fontSize: "0.875rem", position: "static", top: 0 }} />
						</Tooltip>
					</SubDiv>
					<Typography
						variant="caption"
						sx={{
							padding: "0rem 0rem 0rem 0rem",
						}}
					>
						Beegru Points
					</Typography>
				</PointsContainer>

				<div
					style={{
						margin: "0rem 0rem 0rem 0.5rem",
						borderRight: "1px solid #E0E0E0",
						height: "3.5rem",
					}}
				/>

				{/* Earned Beegru Points */}

				<PointsContainer sx={{ margin: "0rem 0rem 0rem 0.5rem" }}>
					<SubDiv sx={{ alignItems: "flex-start" }}>
						<Links
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.7rem",
							}}
							href={
								"/users/" +
								(session
									? session.user
										? session.user.dbUser
											? session.user.dbUser.slug && session.user.dbUser.slug.length > 0
												? session.user.dbUser.slug
												: session.user.dbUser._id
													? session.user.dbUser._id
													: ""
											: ""
										: ""
									: "") +
								"/account-settings"
							}
							rel="noopener"
							target="_blank"
							referrerPolicy="no-referrer"
						>
							<SvgIcon
								component={Points}
								inheritViewBox={false}
								viewBox="0 0 42 42"
								width={24}
								height={24}
								sx={{
									width: "1.125rem",
									height: "1.125rem",
								}}
							/>
							<Text sx={{ width: "100%", fontSize: "1.25rem" }}>
								{session.user.dbUser.earned_beegru_points ? session.user.dbUser.earned_beegru_points.toFixed(1) : 0}
							</Text>
						</Links>

						<Tooltip title="These are the Beegru Points that you earned just by staying active on the platform">
							<InfoOutlined sx={{ fontSize: "0.875rem" }} />
						</Tooltip>
					</SubDiv>
					<Typography
						variant="caption"
						sx={{
							padding: "0rem 0rem 0rem 0rem",
						}}
					>
						Points earned
					</Typography>
				</PointsContainer>
			</ContentContainer>

			{/* Dark Mode Switch */}

			<ContentContainer
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "1rem 1rem 0rem 0.8rem",
					gap: "0.5rem",
				}}
			>
				{theme.palette.mode === "dark" ? <DarkMode /> : <LightMode sx={{ color: "#757575" }} />}
				{/* <TypeWithNameContainer>
					<Typography variant="body2">Default theme</Typography>
					<TypeTypo variant="caption">{theme.palette.mode === "dark" ? "Dark mode" : "Light mode"}</TypeTypo>
				</TypeWithNameContainer> */}
				<Text variant="h6">{theme.palette.mode === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode"}</Text>
				<ColorModeContext.Consumer>
					{({ toggleColorMode }) => (
						<FormControlLabel
							onChange={toggleColorMode}
							control={<MaterialUISwitch checked={theme.palette.mode === "dark" ? true : false} />}
							label=""
							labelPlacement="start"
						/>
					)}
				</ColorModeContext.Consumer>
			</ContentContainer>

			{/* Your Profiles */}

			{!loading ? (
				<ContentContainer
					sx={{
						padding: "1rem 1rem 0rem 0.8rem",
					}}
				>
					<Accordion
						expanded={expanded}
						onChange={() => setExpanded(!expanded)}
						sx={{
							width: "100%",
							background: theme.palette.background.paper,
							padding: "0rem",
							margin: "0rem",
							border: "none",
						}}
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
							sx={{
								padding: expanded === true ? "0rem 0rem 0.5rem 0rem" : "0rem 0rem 0.5rem 0rem",
							}}
						>
							<ManageAccountsOutlined
								sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }}
							/>
							<Text sx={{ padding: "0rem 0rem 0rem 0.5rem" }}>Your profiles</Text>
						</AccordionSummary>

						<AccordionDetails>
							<RadioGroup
								aria-labelledby="your-profiles"
								name="your-profiles"
								sx={{
									margin: "0.5rem 0rem 0rem 0.5rem",
								}}
								value={radio}
								onChange={(e) => {
									setRadio(e.target.value);
									onChangeProfileContext(e.target.value);
								}}
							>
								<RadioWithLink>
									<CustomFormControlLabel
										value={session_user._id}
										control={<Radio size="small" />}
										label={
											<TypeWithNameLink
												href={"/users/" + (session_user.slug || session_user._id)}
												rel="noopener"
												target="_blank"
												referrerPolicy="no-referrer"
											>
												<Heading sx={{ width: "100%" }}>{userName}</Heading>
												<TypeTypo>User profile</TypeTypo>
											</TypeWithNameLink>
										}
									/>
									<a
										href={"/users/" + (session_user.slug || session_user._id)}
										rel="noopener"
										target="_blank"
										referrerPolicy="no-referrer"
									>
										<OpenInNew
											sx={{
												fontSize: "1.5rem",
												color: theme.palette.primary.main,
											}}
										/>
									</a>
								</RadioWithLink>

								{businessProfiles.slice(0, 1).map((page: any, index: number) => (
									<RadioWithLink key={index}>
										<CustomFormControlLabel
											key={index}
											value={page.page_id}
											control={<Radio size="small" />}
											label={
												<TypeWithNameLink
													href={"/" + page.page_type + "s" + "/" + page.page_slug}
													rel="noopener"
													target="_blank"
													referrerPolicy="no-referrer"
												>
													<Heading>{page.page_title}</Heading>
													<TypeTypo>
														{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1) + " profile"}
													</TypeTypo>
												</TypeWithNameLink>
											}
										/>
										<a
											href={"/" + page.page_type + "s" + "/" + page.page_slug}
											rel="noopener"
											target="_blank"
											referrerPolicy="no-referrer"
										>
											<OpenInNew
												sx={{
													fontSize: "1.5rem",
													color: theme.palette.primary.main,
												}}
											/>
										</a>
									</RadioWithLink>
								))}
							</RadioGroup>
						</AccordionDetails>
					</Accordion>
				</ContentContainer>
			) : (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			)}

			{/* Your Profiles

			{!loading ? (
				<ContentContainer
					sx={{
						padding: "1rem 1rem 0rem 0.8rem",
					}}
				>
					<Accordion
						expanded={expanded}
						onChange={() => setExpanded(!expanded)}
						sx={{
							width: "100%",
							background: theme.palette.background.paper,
							padding: "0rem",
							margin: "0rem",
							border: "none",
						}}
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
							sx={{
								padding: expanded === true ? "0rem 0rem 0.5rem 0rem" : "0rem 0rem 0.5rem 0rem",
							}}
						>
							<ManageAccountsOutlined
								sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }}
							/>
							<Text sx={{ padding: "0rem 0rem 0rem 0.5rem" }}>Your profiles</Text>
						</AccordionSummary>

						<AccordionDetails>
							<RadioGroup
								aria-labelledby="your-profiles"
								name="your-profiles"
								sx={{
									margin: "0.5rem 0rem 0rem 0.5rem",
								}}
								value={radio}
								onChange={(e) => {
									setRadio(e.target.value);
									onChangeProfileContext(e.target.value);
								}}
							>
								<RadioWithLink>
									<CustomFormControlLabel
										value={session_user._id}
										control={<Radio size="small" />}
										label={
											<TypeWithNameLink
												href={"/users/" + (session_user.slug || session_user._id)}
												rel="noopener"
												target="_blank"
												referrerPolicy="no-referrer"
											>
												<Heading sx={{ width: "100%" }}>{userName}</Heading>
												<TypeTypo>User</TypeTypo>
											</TypeWithNameLink>
										}
									/>
									<a
										href={"/users/" + (session_user.slug || session_user._id)}
										rel="noopener"
										target="_blank"
										referrerPolicy="no-referrer"
									>
										<OpenInNew
											sx={{
												fontSize: "1.5rem",
												color: theme.palette.primary.main,
											}}
										/>
									</a>
								</RadioWithLink>

								{showMore
									? businessProfiles.map((page: any, index: number) => (
											<RadioWithLink key={index}>
												<CustomFormControlLabel
													key={index}
													value={page.page_id}
													control={<Radio size="small" />}
													label={
														<TypeWithNameLink
															href={"/" + page.page_type + "s" + "/" + page.page_slug}
															rel="noopener"
															target="_blank"
															referrerPolicy="no-referrer"
														>
															<Heading>{page.page_title}</Heading>
															<TypeTypo>{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}</TypeTypo>
														</TypeWithNameLink>
													}
												/>
												<a
													href={"/" + page.page_type + "s" + "/" + page.page_slug}
													rel="noopener"
													target="_blank"
													referrerPolicy="no-referrer"
												>
													<OpenInNew
														sx={{
															fontSize: "1.5rem",
															color: theme.palette.primary.main,
														}}
													/>
												</a>
											</RadioWithLink>
										))
									: businessProfiles.slice(0, 2).map((page: any, index: number) => (
											<RadioWithLink key={index}>
												<CustomFormControlLabel
													key={index}
													value={page.page_id}
													control={<Radio size="small" />}
													label={
														<TypeWithNameLink
															href={"/" + page.page_type + "s" + "/" + page.page_slug}
															rel="noopener"
															target="_blank"
															referrerPolicy="no-referrer"
														>
															<Heading>{page.page_title}</Heading>
															<TypeTypo>{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}</TypeTypo>
														</TypeWithNameLink>
													}
												/>
												<a
													href={"/" + page.page_type + "s" + "/" + page.page_slug}
													rel="noopener"
													target="_blank"
													referrerPolicy="no-referrer"
												>
													<OpenInNew
														sx={{
															fontSize: "1.5rem",
															color: theme.palette.primary.main,
														}}
													/>
												</a>
											</RadioWithLink>
										))}
								{businessProfilesCount > 2 && (
									<ShowMore
										onClick={() => {
											if (businessProfiles.length < businessProfilesCount) {
												handleClickShowMoreBusinessProfiles();
												setShowMore(!showMore);
											} else {
												setShowMore(!showMore);
											}
										}}
									>
										{showMore ? "Show less..." : "Show more..."}
									</ShowMore>
								)}
							</RadioGroup>

							<ContentRow
								onClick={handleOpenBusinessProfile}
								sx={{
									justifyContent: "flex-start",
									gap: "0.4rem",
									color: "#F6843B",
									cursor: "pointer",
								}}
							>
								<Add
									sx={{
										fontSize: "1rem",
										margin: "0rem 0rem 0rem 0rem",
										color: theme.palette.mode == "dark" ? "#ffffff" : "#575757",
									}}
								/>
								<Text
									variant="h6"
									sx={{
										color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
									}}
								>
									Create business profile
								</Text>
							</ContentRow>
						</AccordionDetails>
					</Accordion>
				</ContentContainer>
			) : (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			)} */}

			{/* Country Dropdown */}

			{/* <ContentContainer
				sx={{
					borderTop: "1px solid #E5E5E5",
					cursor: "pointer",
				}}
			>
				<CountrySelectDiv>
					<Select
						variant="outlined"
						labelId="demo-simple-select-standard-label"
						id="country-select"
						value={countrySelect}
						onChange={async (event: any) => {
							await dispatch(setLanguageThunk(event.target.value));
							setcountrySelect(event.target.value);
						}}
						// onChange={(e) => setcountrySelect(e.target.value)}
						label="Country"
						disableUnderline={true}
						MenuProps={{
							disableScrollLock: true,
							MenuListProps: {
								sx: {
									backgroundColor: theme.palette.mode === "dark" ? "#212B36" : "#FFFFFF",
								},
							},
						}}
						sx={{ width: 130 }}
					>
						<MenuItem value={"united-states"}>
							<Text variant="h6">United States</Text>
						</MenuItem>
						<MenuItem value={"united-kingdom"}>
							<Text variant="h6">United Kingdom</Text>
						</MenuItem>
						<MenuItem value={"india"}>
							<Text variant="h6">India</Text>
						</MenuItem>
						<MenuItem value={"singapore"}>
							<Text variant="h6">Singapore</Text>
						</MenuItem>
						<MenuItem value={"australia"}>
							<Text variant="h6">Australia</Text>
						</MenuItem>
					</Select>
					<Text variant="h6">
						{langaugeSelect} | {currency}
					</Text>
				</CountrySelectDiv>
			</ContentContainer> */}

			{/* Settings */}

			<Links
				href={
					"/users/" +
					(session
						? session.user
							? session.user.dbUser
								? session.user.dbUser.slug && session.user.dbUser.slug.length > 0
									? session.user.dbUser.slug
									: session.user.dbUser._id
										? session.user.dbUser._id
										: ""
								: ""
							: ""
						: "") +
					"/account-settings"
				}
				rel="noopener"
				target="_blank"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						// borderTop: "1px solid #E5E5E5",
						cursor: "pointer",

						padding: "0rem 1rem 0rem 1rem",
					}}
				>
					<ContentRow>
						<SettingsOutlined sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }} />
						<Text variant="h6">Settings</Text>
					</ContentRow>
				</ContentContainer>
			</Links>

			{/* FAQs */}

			<Links
				href="/faqs"
				rel="noopener"
				target="_blank"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						// borderTop: "1px solid #E5E5E5",
						cursor: "pointer",
					}}
				>
					<ContentRow>
						<LiveHelpOutlined sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }} />
						<Text variant="h6">FAQs</Text>
					</ContentRow>
				</ContentContainer>
			</Links>

			{/* T&C */}

			{/* <Links href="/terms-of-use"
      rel="noopener"
        target="_blank"
        referrerPolicy="no-referrer">
        <ContentContainer
          sx={{
            // borderTop: "1px solid #E5E5E5",
            cursor: "pointer",
          }}
        >
          <ContentRow>
            <DescriptionOutlined />
            <Text variant="h6">Terms of use</Text>
          </ContentRow>
        </ContentContainer>
      </Links> */}

			{/* Privacy Policy */}
			{/*
      <Links href="/privacy"
      rel="noopener"
        target="_blank"
        referrerPolicy="no-referrer">
        <ContentContainer
          sx={{
            // borderTop: "1px solid #E5E5E5",
            cursor: "pointer",
          }}
        >
          <ContentRow>
            <PolicyOutlined />
            <Text variant="h6">Privacy Policy</Text>
          </ContentRow>
        </ContentContainer>
      </Links> */}

			{/* Logout */}

			<ContentContainer
				sx={{
					// borderTop: "1px solid #E5E5E5",
					cursor: "pointer",
					padding: "0rem 1rem 1rem 1rem",
				}}
				onClick={() => {
					signOut({
						callbackUrl:
							process.env.PRODUCTION_API_URL +
							"auth/end-session?sessionId=" +
							encodeURIComponent(session.session_id) +
							"&callbackUrl=" +
							encodeURIComponent(router.asPath),
					});
				}}
			>
				<ContentRow>
					<Logout sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }} />
					<Text variant="h6">Logout</Text>
				</ContentRow>
			</ContentContainer>
		</React.Fragment>
	);
};

export default UserContent;
