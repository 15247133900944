/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";
import CapitalizeFirstLetter from "../../../../../lib/CapitalizeFirstLetter";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));



const GridCardAreaAndConfig = ({ propertyType }: { propertyType: Array<string> }) => {
	const theme = useTheme();

	return (
		<Container>
			<Typography variant="body1">
				{propertyType[0].length > 18
					? CapitalizeFirstLetter(propertyType[0]).substring(0, 18) + "..."
					: CapitalizeFirstLetter(propertyType[0])}
			</Typography>

			<Typography
				variant="body2"
				color={theme.palette.text.secondary}
			>
				Type
			</Typography>
		</Container>
	);
};

export default GridCardAreaAndConfig;
