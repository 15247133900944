/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../redux-magic/store";
import { ObjectId } from "mongodb";
import ShareModal from "../../../modals/ShareModal";

/* Component Imports */

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IconButton, Menu, MenuItem, Skeleton, Tooltip, useTheme, Paper, Box, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import { MoreVertOutlined } from "@mui/icons-material";
import DeleteListingPropertyModal from "../../../modals/PropertyModal/DeleteListingProperty";
import { signIn } from "next-auth/react";
import BoltIcon from "@mui/icons-material/Bolt";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "#FEEADA",
	padding: "0.25rem",
	width: "2rem",
	height: "2rem",
	borderRadius: "12px",
	"&:hover": {
		background: "#E6DACF",
	},
}));

const BoltContainer = styled("div")(({ theme }) => ({
	background: "white",
	"&:hover": {
		background: "white",
	},
}));

const ThreeDotIconButton = styled(IconButton)(({ theme }) => ({
	background: "#00000040",
	margin: "0.5rem 0.5rem 0rem 0rem",
	"&:hover": {
		background: "#00000040",
	},
}));

const CarouselComp = styled(Carousel)(({ theme }) => ({
	"& .carousel .control-dots .dot": {
		display: "none",
		backgroundColor: "#FFFFFF",
		opacity: 1,
	},
	"& .carousel .control-arrow, .carousel.carousel-slider .control-arrow": {
		margin: "0.5rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		top: "45%",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "5rem",
		opacity: 1,
		height: "2rem",
		width: "2rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const BackArrow = styled(ArrowBackIosNewIcon)(({ theme }) => ({
	position: "absolute",
	width: "25px",
	height: "25px",
	zIndex: 1,
	top: "45%",
	color: theme.palette.primary.main,
	[theme.breakpoints.down("lg")]: {
		display: "none",
	},
}));

const ForwardArrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
	position: "absolute",
	width: "25px",
	height: "25px",
	zIndex: 1,
	top: "45%",
	right: "0",
	color: theme.palette.primary.main,
	[theme.breakpoints.down("lg")]: {
		display: "none",
	},
}));

/*
setting a constant image for when the image is not available or something goes wrong
*/

const fallbackImage: string = process.env.CDN_URL + "images/uploads/placeholder_1080p_old.svg";

const ServiceCardImage = ({ images }: { images: string }) => {
	return (
		<React.Fragment>
			<Container>
				<Image
					sx={{
						objectFit: images[0].includes("images/uploads/placeholder_1080p_old.svg") ? "fill" : "cover",
					}}
					src={images ? images : fallbackImage}
					alt="No Images Available"
					referrerPolicy="no-referrer"
				/>
			</Container>
		</React.Fragment>
	);
};

export default ServiceCardImage;
