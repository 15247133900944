/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 1rem",
}));

const GridCardLocation = ({ location }: { location: string }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Heading>
				<Typography variant="body1">
					{location ? (location.length < 13 ? location : location?.substring(0, 13) + "...") : ""}
				</Typography>
				<Typography
					variant="body2"
					color={theme.palette.text.secondary}
				>
					Location
				</Typography>
			</Heading>
		</React.Fragment>
	);
};

export default GridCardLocation;
