/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "12.063rem",
	borderRadius: "16px 16px 0px 0px",
}));

const fallbackImage: string = process.env.CDN_URL + "images/uploads/placeholder_1080p_old.svg";

const GridImage = ({ images }: { images: string }) => {
	return (
		<React.Fragment>
			<Container>
				<Image
					src={images ? images : fallbackImage}
					alt="Cover Image"
					referrerPolicy="no-referrer"
				/>
			</Container>
		</React.Fragment>
	);
};

export default GridImage;
