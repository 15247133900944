/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	lineHeight: "1.75rem",
	margin: "0.35rem 0rem 0.45rem 0.25rem",
}));

const GridCardTitle = ({ title }: { title: string }) => {
	return (
		<React.Fragment>
			<Heading variant="body1">{title ? (title.length < 30 ? title : title?.substring(0, 30) + "...") : ""}</Heading>
		</React.Fragment>
	);
};

export default GridCardTitle;
