/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { Popover, useTheme } from "@mui/material";
import Initials from "../../cards/Initials";
import UserContent from "./custom/UserContent";

/* Styled Components */

const UserContainer = styled("div")(({ theme }) => ({
	width: "2.625rem",
	height: "2.625rem",
	cursor: "pointer",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	width: "2.625rem",
	height: "2.625rem",
	borderRadius: "8px",
	objectFit: "cover",
}));

const UserMenu = ({
	session,
	dispatch,
	profile_context,
	new_business_profile_url,
	redux_session_updated,
}: {
	session: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	redux_session_updated?: boolean;
}) => {
	const theme = useTheme();

	const userName =
		session?.user?.dbUser?.first_name !== ""
			? session?.user?.dbUser?.first_name + " " + session?.user?.dbUser?.last_name
			: "New User";

	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

	const handleClick = (event: React.MouseEvent<Element>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [businessProfilePicture, setBusinessProfilePicture] = React.useState(
		profile_context
			? profile_context.is_business_profile
				? profile_context.business_profile_image
					? profile_context.business_profile_image.exists
						? profile_context.business_profile_image.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg",
	);

	React.useEffect(() => {
		setBusinessProfilePicture(
			profile_context
				? profile_context.is_business_profile
					? profile_context.business_profile_image
						? profile_context.business_profile_image.exists
							? profile_context.business_profile_image.file_id
							: "/images/icons/Avatar.svg"
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg",
		);
	}, [profile_context]);

	const profilePicture = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.profile_picture
					? (session.user.dbUser.profile_picture.exists as boolean)
						? session.user.dbUser.profile_picture.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg"
		: "/images/icons/Avatar.svg";

	React.useEffect(() => {
		session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.profile_picture
						? (session.user.dbUser.profile_picture.exists as boolean)
							? session.user.dbUser.profile_picture.file_id
							: "/images/icons/Avatar.svg"
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg";
	}, [session]);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const profilepicchecks = profile_context
		? profile_context.is_business_profile
			? businessProfilePicture
			: profilePicture
		: profilePicture;

	const check =
		profile_context && profile_context.is_business_profile
			? profile_context && profile_context.business_profile_id
			: profile_context && profile_context.user_id;

	return (
		<React.Fragment>
			<UserContainer onClick={handleClick}>
				{!profilepicchecks.includes("/images/icons/Avatar.svg") ? (
					<ProfilePicture
						src={`/profile-picture/${profilepicchecks}`}
						alt="user"
						referrerPolicy="no-referrer"
					/>
				) : (
					<div
						style={{
							display: "flex",
						}}
					>
						<Initials
							title={
								profile_context ? (profile_context.is_business_profile ? profile_context.page_title : userName) : ""
							}
							profiledropdown={false}
							header={true}
							comment={false}
							postcard={false}
							businessprofile={false}
						/>
					</div>
				)}
			</UserContainer>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				disableScrollLock={true}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				slotProps={{
					paper: {
						sx: {
							width: "17.5rem",
							height: "auto",
							borderRadius: "16px",
							margin: "0.5rem 0rem 0rem 0rem",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							scrollbarWidth: "none",
						},
					},
				}}
			>
				<UserContent
					session={session}
					dispatch={dispatch}
					profilePicture={profilepicchecks}
					profile_context={profile_context}
					new_business_profile_url={new_business_profile_url}
					redux_session_updated={redux_session_updated}
				/>
			</Popover>
		</React.Fragment>
	);
};
export default UserMenu;
