/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { useTheme, Typography, Skeleton } from "@mui/material";
import BeegruButton from "../../../common-components/buttons/BeegruButton";

/* Icon Imports */

import PremiumBadge from "../../../../public/images/badges/premium.svg";
import VerifiedPremiumBadge from "../../../../public/images/badges/verified_premium.svg";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
}));

const ServiceCardProviderAndContact = ({
	loading,
	service_provider_name,
	show_contact_details,
}: {
	loading: boolean;
	service_provider_name: string;
	show_contact_details: boolean;
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	}

	return (
		<MainContainer>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "flex-end",
					width: "100%",
					margin: "0rem 0rem 0rem 0rem",
				}}
			>
				{/* Service Provider */}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						alignItems: "flex-start",
						height: "100%",
						width: "46%",
					}}
				>
					<Typography variant="body1">
						{service_provider_name.length > 18 ? service_provider_name.substring(0, 18) : service_provider_name}
					</Typography>
					{/* {is_subscribed === true && is_kyc_verified === true ? (
						<Tooltip title="Verified Premium">
							<SvgIcon
								component={VerifiedPremiumBadge}
								inheritViewBox={false}
								viewBox="0 0 24 24"
								width={24}
								height={24}
								sx={{
									width: "1rem",
									height: "1rem",
								}}
							/>
						</Tooltip>
					) : is_subscribed === true && is_kyc_verified === false ? (
						<Tooltip title="Premium">
							<SvgIcon
								component={PremiumBadge}
								inheritViewBox={false}
								viewBox="0 0 24 24"
								width={24}
								height={24}
								sx={{
									width: "1rem",
									height: "1rem",
								}}
							/>
						</Tooltip>
					) : null} */}
					<Typography
						variant="body2"
						color={theme.palette.text.secondary}
					>
						Service Provider
					</Typography>
				</div>

				{/* CTA Button */}

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "52%",
						padding: "0rem 0rem 0rem 0.75rem",
					}}
				>
					{!show_contact_details ? (
						<BeegruButton
							variant="outlined"
							color="primary"
							size="small"
							sx={{
								fontSize: "0.875rem",
								lineHeight: "1rem",
								letterSpacing: "0.025rem",
								fontWeight: 400,
								width: "100%",
								height: "100%",
								padding: "0.5rem 0.5rem 0.5rem 0.5rem",
							}}
						>
							Get a callback
						</BeegruButton>
					) : (
						<BeegruButton
							variant="outlined"
							color="primary"
							size="small"
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								fontSize: "0.875rem",
								lineHeight: "1rem",
								letterSpacing: "0.025rem",
								fontWeight: 400,
								height: "100%",
								width: "100%",
								padding: "0.438rem 0.5rem 0.438rem 0.5rem",
								// gap: "0.5rem",
							}}
							startIcon={<LocalPhoneOutlinedIcon />}
						>
							Call now
						</BeegruButton>
					)}
				</div>
			</div>
		</MainContainer>
	);
};

export default ServiceCardProviderAndContact;
