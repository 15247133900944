/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme } from "@mui/material";

/* Styled Components */

const Heading2 = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));

const GridCardAreaAndConfig = ({ propertyType }: { propertyType: Array<string> }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<Heading2>
				<Typography variant="body1">
					{propertyType[0]?.length > 12 ? propertyType[0]?.substring(0, 12) + "..." : propertyType[0]}
				</Typography>

				<Typography
					variant="body2"
					color={theme.palette.text.secondary}
				>
					Type
				</Typography>
			</Heading2>
		</React.Fragment>
	);
};

export default GridCardAreaAndConfig;
