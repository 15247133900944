/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
	createNewPropertyWithUploadToolThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyDefaultLocationThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyOverviewThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyUploadImagesThunk,
} from "../../../redux-magic/store";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";
import location_data from "../../../redux-magic/sub-state-data/location-lat-lng";

/* Component Imports */

// import Initials from "../../cards/Initials";
import PropertyCard from "../../cards/PropertyCardPublishPreview/PropertyCardPublishPreview";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import PropertyConfig from "./sub-components/CreatePropertySubComponents/PropertyConfig";
import PropertyDetails from "./sub-components/CreatePropertySubComponents/PropertyDetails";
import PropertyLocation from "./sub-components/CreatePropertySubComponents/PropertyLocation";
import PropertyOverview from "./sub-components/CreatePropertySubComponents/PropertyOverview";
import UploadImages from "./sub-components/CreatePropertySubComponents/UploadImages";

/* Component Imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

/* Icon Imports */

import { ArrowBack, Close, InfoOutlined } from "@mui/icons-material";

/* library imports */

import { security_deposit } from "../../../lib/data/search-filters";
import { property_images } from "../../../redux-magic/sub-interfaces/new-property-data-interface";

/* Styled Components */

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));
const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));
const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
			// content: `${text[3]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "2rem",
	[theme.breakpoints.down("sm")]: {
		padding: "1rem",
	},
	"& .MuiDialog-paper": {
		borderRadius: "1rem",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			margin: "0rem",
			width: "100%",
		},
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "1rem",
	padding: "1rem 2rem 0rem 2rem",
	[theme.breakpoints.down("sm")]: {
		padding: "1rem 1rem 0rem 1rem",
	},
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.5rem",
	padding: "2rem 2rem 2rem 2rem",
	background: theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		padding: "1rem ",
	},
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const StepDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "2.5rem",
	background: theme.palette.mode == "dark" ? "#FFFFFF" : "var(--Text-New-Secondary, #666)",
	padding: "0rem 0.5rem",
	gap: "0.5rem",
	height: "1.5rem",
	width: "2.25rem",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	fontWeight: 400,
	color: theme.palette.mode == "dark" ? "#212121" : "#ffffff",
	padding: "0rem",
}));

const PropertyNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "2rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const ColorLinearProgress = styled(LinearProgress)(({ theme }) => ({
	"&.MuiLinearProgress-colorPrimary": {
		backgroundColor: "#E0E0E0",
	},
	"& .MuiLinearProgress-bar": {
		backgroundColor: theme.palette.primary.main,
	},
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

interface Area {
	label: string;
	enableLabel: boolean;
	land_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	super_built_up_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	carpet_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	karab_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
}

interface BHK {
	min: number;
	max: number;
	label: string;
	enableLabel: boolean;
}

const CreatePropertyWithUploadTool = ({
	open,
	handleClose,
	dispatch,
	new_listing_url,
	session,
	newPropertyData,
	studio,
}: {
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	session: any;
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
}) => {
	const theme = useTheme();

	/* User business profiles */

	let businessProfiles: DbUserPage = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: null
				: null
			: null
		: null;

	const businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const [activeStep, setActiveStep] = React.useState(0);

	const [propertyTitle, setPropertyTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [newListing, setNewListing] = React.useState<boolean>(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [imgPreview, setImgPreview] = React.useState<string>("");

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		propertyTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// Extract current location values
	const currentLatitude = newPropertyData?.search_metrics.location_coordinates[0];
	const currentLongitude = newPropertyData?.search_metrics.location_coordinates[1];

	// Extract previous location values (assuming location_data is available in scope)
	const previousLatitude = location_data.location[0];
	const previousLongitude = location_data.location[1];

	// Check if location value has changed
	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	// lets check if the 2nd step is complete
	const stepTwoComplete: boolean =
		activeStep === 2 &&
		newPropertyData?.transaction_type !== "ofb" &&
		(newPropertyData?.transaction_type === "" ||
			newPropertyData?.property_category === "" ||
			newPropertyData?.property_type?.length === 0 ||
			newPropertyData?.price?.min === 0 ||
			newPropertyData?.price?.min === null ||
			newPropertyData?.price?.max === 0 ||
			newPropertyData?.price?.max === null ||
			(!newPropertyData?.property_type?.includes("Land (Residential)") &&
				!newPropertyData?.property_type?.includes("Land (Commercial)") &&
				!newPropertyData?.property_type?.includes("Land (Agricultural)") &&
				!newPropertyData?.property_type?.includes("Land (Industrial)") &&
				!newPropertyData?.property_type?.includes("Mining") &&
				newPropertyData?.availability?.length === 0) ||
			((newPropertyData?.availability?.includes("Under construction") ||
				newPropertyData?.availability?.includes("under_construction") ||
				newPropertyData?.availability?.includes("Select date") ||
				newPropertyData?.availability?.includes("select_date")) &&
				newPropertyData?.possession_date === ""));

	// lets check if the 3rd step is complete
	const stepThreeCompelte: boolean =
		activeStep === 3 &&
		((!newPropertyData?.property_type.includes("Plot") &&
			!newPropertyData?.property_type.includes("Farm Plot") &&
			!newPropertyData?.property_type.includes("Plot (Commercial)") &&
			!newPropertyData?.property_type.includes("Land (Residential)") &&
			!newPropertyData?.property_type.includes("Land (Commercial)") &&
			!newPropertyData?.property_type.includes("Land (Agricultural)") &&
			!newPropertyData?.property_type.includes("Land (Industrial)") &&
			studio === false &&
			!newPropertyData?.property_type.includes("Building") &&
			!newPropertyData?.property_type.includes("Shop") &&
			!newPropertyData?.property_type.includes("Office Space") &&
			!newPropertyData?.property_type.includes("Co-working") &&
			!newPropertyData?.property_type.includes("Warehouse") &&
			!newPropertyData?.property_type.includes("Factory") &&
			!newPropertyData?.property_type.includes("Mining") &&
			(newPropertyData?.bhk.min === 0 ||
				newPropertyData?.bhk.min === undefined ||
				newPropertyData?.bhk.max === 0 ||
				newPropertyData?.bhk.max === null)) ||
			/* No. of seats */

			(newPropertyData?.property_type.includes("Co-working") &&
				(newPropertyData?.no_of_seats.max === 0 || newPropertyData?.no_of_seats.max === null)) ||
			/* No. of units */

			(newPropertyData?.transaction_type == "buy" &&
				(newPropertyData?.property_type.includes("Apartment") ||
					newPropertyData?.property_type.includes("Plot") ||
					newPropertyData?.property_type.includes("Farm Plot") ||
					newPropertyData?.property_type.includes("Plot (Commercial)") ||
					newPropertyData?.property_type.includes("Villa") ||
					newPropertyData?.property_type.includes("Row House") ||
					newPropertyData?.property_type.includes("Builder Floor Apartment") ||
					newPropertyData?.property_type.includes("Studio Apartment") ||
					newPropertyData?.property_type.includes("Penthouse")) &&
				(newPropertyData?.no_of_units.max === 0 || newPropertyData?.no_of_units.max === null)) ||
			/* Plot Dimensions */

			((newPropertyData?.property_type.includes("Plot") ||
				newPropertyData?.property_type.includes("Farm Plot") ||
				newPropertyData?.property_type.includes("Plot (Commercial)")) &&
				newPropertyData?.plot_dimensions.length === 0) ||
			/* Land Area */

			((newPropertyData?.transaction_type == "buy" ||
				(newPropertyData?.transaction_type == "rent" &&
					(newPropertyData?.property_type.includes("Land (Residential)") ||
						newPropertyData?.property_type.includes("Land (Commercial)") ||
						newPropertyData?.property_type.includes("Land (Agricultural)") ||
						newPropertyData?.property_type.includes("Land (Industrial)") ||
						newPropertyData.property_type.includes("Mining")))) &&
				(newPropertyData?.area.land_area.max.acre === 0 || newPropertyData?.area.land_area.max.acre === null)) ||
			/* Super Built Up Area */

			(((newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Independent House")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Co-living/PG")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Building")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Shop")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Office Space")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Co-working")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Warehouse")) ||
				(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Factory")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Independent House")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Apartment")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Villa")) ||
				(newPropertyData?.transaction_type == "rent" &&
					newPropertyData?.property_type.includes("Builder Floor Apartment")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Penthouse")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Row House")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Plot")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Farm Plot")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Plot (Commercial)")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Farm House")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Warehouse")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Factory")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Building")) ||
				(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Shop")) ||
				(newPropertyData?.transaction_type == "resale" &&
					newPropertyData?.property_type.includes("Independent House")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Co-living/PG")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Building")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Shop")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Office Space")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Co-working")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Plot")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Farm Plot")) ||
				(newPropertyData?.transaction_type == "resale" &&
					newPropertyData?.property_type.includes("Plot (Commercial)")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Farm House")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Warehouse")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Apartment")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Villa")) ||
				(newPropertyData?.transaction_type == "resale" &&
					newPropertyData?.property_type.includes("Builder Floor Apartment")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Penthouse")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Row House")) ||
				(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Factory"))) &&
				(newPropertyData?.area.super_built_up_area.min.ft === 0 ||
					newPropertyData?.area.super_built_up_area.min.ft === null ||
					newPropertyData?.area.super_built_up_area.max.ft === 0 ||
					newPropertyData?.area.super_built_up_area.max.ft === null)));

	// lets check if the 4th step is complete

	const stepFourComplete: boolean = activeStep === 4 && newPropertyData?.description === "";

	// lets check if the 4th step is complete

	const stepFiveComplete: boolean = activeStep === 5 && newPropertyData?.images.length === 0;

	// lets create functions to handle the stepper
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Create new property function */
	const propertyImageObject = newPropertyData?.images.map((image: property_images) => {
		return {
			file_id: image.file_id,
			mime_type: image.mime_type,
			category: image.category,
			caption: image.caption,
			priority: image.priority,
		};
	});


	const handleClickCreateNewProperty = async () => {
		setLoading(true);
		setNewListing(true), setLoadNext(true);
		await dispatch(
			createNewPropertyWithUploadToolThunk({
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id,
				creatorIsBusinessProfile: true,
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles?.page_title,
				businessProfileType: createBusinessProfile ? businessProfileType.toLowerCase() : businessProfiles?.page_type,
				userId: session.user.dbUser._id,
				phoneNumber: session.user.dbUser.phone,
				countryCode: session.user.dbUser.country_code,
				propertyName: propertyTitle,
				propertyLocationName: newPropertyData?.location.title,
				propertyLocationCoordinates: newPropertyData?.search_metrics.location_coordinates,
				propertyTransactionType: newPropertyData?.transaction_type,
				propertyCategory: newPropertyData?.property_category,
				propertyType: newPropertyData?.property_type,
				propertySubtype: newPropertyData?.property_subtype,
				propertyMinPrice: newPropertyData?.price.min,
				propertyMaxPrice: newPropertyData?.price.max,
				propertySecurityDeposit: newPropertyData?.security_deposit.max,
				propertyStatus: newPropertyData?.availability[0],
				propertyPossessionDate: newPropertyData?.possession_date,
				propertyAge: newPropertyData?.property_age,
				propertyFacing: newPropertyData?.unit_facing,
				propertyFurnishing: newPropertyData?.furnished,
				propertyMinBedrooms: newPropertyData?.bhk.min,
				propertyMaxBedrooms: newPropertyData?.bhk.max,
				propertyMinBathrooms: newPropertyData?.bathroom.min,
				propertyMaxBathroom: newPropertyData?.bathroom.max,
				propertyNoOfBalconies: newPropertyData?.balconies.max,
				propertyNoOfSeats: newPropertyData?.no_of_seats.max,
				propertyNoOfUnits: newPropertyData?.no_of_units.max,
				propertyNoOfFloors: newPropertyData?.floors.max,
				propertyPlotDimensions: newPropertyData?.plot_dimensions,
				propertyLandAreaUnit: newPropertyData?.area.land_area.land_area_unit,
				propertyLandArea: newPropertyData?.area.land_area.max.acre,
				propertySuperBuiltUpAreaUnit: newPropertyData?.area.super_built_up_area.super_built_up_area_unit,
				propertyMinSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.min.ft,
				propertyMaxSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.max.ft,
				propertyIsCarpetAreaPercentage: true,
				propertyCarpetAreaPercentage: "0",
				propertyCarpetAreaUnit: newPropertyData?.area.carpet_area.carpet_area_unit,
				propertyMinCarpetArea: newPropertyData?.area.carpet_area.min.ft,
				propertyMaxCarpetArea: newPropertyData?.area.carpet_area.max.ft,
				propertyImages: propertyImageObject,
				propertyOverview: newPropertyData?.description,
			}),
		);

		if (newPropertyData.currentPage === "navbar") {
			const businessType: string = createBusinessProfile
				? businessProfileType.toLowerCase()
				: businessProfiles?.page_type;

			const businessProfileSlug = createBusinessProfile ? new_listing_url : businessProfiles?.page_slug;

			window.open(`/${businessType}s/${businessProfileSlug}`, "_self");
		} else {
			handleModalClose();
		}
	};

	// React.useEffect(() => {
	// 	const businessType: string = createBusinessProfile
	// 		? businessProfileType.toLowerCase()
	// 		: businessProfiles?.page_type;
	// 	if (new_listing_url !== undefined && new_listing_url !== "" && newListing) {
	// 		window.open(`/${businessType}s/${new_listing_url}`, "_self");
	// 	}
	// /* eslint-disable-next-line react-hooks/exhaustive-deps*/
	// }, [new_listing_url]);

	React.useEffect(() => {
		if (newPropertyData?.snack_property_value) {
			setOpenSnackbar(true);
		}
	}, [newPropertyData?.snack_property_value]);

	React.useEffect(() => {
		const businessProfilesCount = session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.business_profiles_count
						? session.user.dbUser.business_profiles_count
						: 0
					: 0
				: 0
			: 0;

		if (businessProfilesCount === 0) {
			setcreateBusinessProfile(true);
		} else {
			setcreateBusinessProfile(false);
		}
	}, [session]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setPropertyTitle("");
		setLoading(false);
		setLoadNext(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewPropertyDefaultLocationThunk({
				location_coordinates: location_data.location,
				address: "",
			}),
		);
		dispatch(setCreateNewPropertyTransactionTypeThunk(""));
		dispatch(setCreateNewPropertyTypeThunk([""]));
		dispatch(setCreateNewPropertySubTypeThunk(""));
		dispatch(setCreateNewPropertyMinPriceThunk(0));
		dispatch(setCreateNewPropertyMaxPriceThunk(0));
		dispatch(setCreateNewPropertySecurityDepositThunk(0));
		dispatch(setCreateNewPropertyAvailabiltyThunk([""]));
		dispatch(setCreateNewePropertyPossessionDateThunk(""));
		dispatch(setCreateNewPropertyUnitFacingThunk([""]));
		dispatch(setCreateNewPropertyLandFacingThunk([""]));
		dispatch(setCreateNewPropertyAgeThunk(0));
		dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		dispatch(setCreateNewPropertyFurnishingThunk([""]));
		dispatch(setCreateNewPropertyLandAreaThunk(0));
		dispatch(setCreateNewPropertyLandAreaUnitThunk(""));
		dispatch(setCreateNewPropertyMaxBalconiesThunk(0));
		dispatch(setCreateNewPropertyMaxBathroomsThunk(0));
		dispatch(setCreateNewPropertyMaxBedroomsThunk(0));
		dispatch(setCreateNewPropertyMaxCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMaxFloorsThunk(0));
		dispatch(setCreateNewPropertyMaxSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNePropertyMinBalconiesThunk(0));
		dispatch(setCreateNewPropertyMinBathroomsThunk(0));
		dispatch(setCreateNewPropertyMinBedroomsThunk(0));
		dispatch(setCreateNewPropertyMinCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMinSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNewPropertyNoOfUnitsThunk(0));
		dispatch(setCreateNewPropertyNoOfSeatsThunk(0));
		dispatch(setCreateNewPropertyFloorNoThunk(0));
		dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(""));
		dispatch(setCreateNewPropertyOverviewThunk(""));
		dispatch(setCreateNewPropertyUploadImagesThunk([]));
		// dispatch(setCreatePropertySelectedImagesUrlThunk([]));
		setAlertModal(false);
	};
	const stepperContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv sx={{ flexDirection: "column", gap: "1rem", paddingTop: "2rem" }}>
						<PropertyNameContainer>
							{businessProfiles === undefined ? (
								<TextContainer sx={{ gap: "0rem" }}>
									<TextContainer sx={{ flexDirection: "row", justifyContent: "flrx-start", alignItems: "center" }}>
										<Typography variant="body1">
											Let&apos;s create a new business profile to post your property{" "}
										</Typography>
										<Tooltip title="Once created, you cannot change the property name">
											<InfoOutlined
												sx={{
													color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
													height: "1.25rem",
													width: "1.25rem",
												}}
											/>
										</Tooltip>
									</TextContainer>

									<TextField
										label="Business Name *"
										required={true}
										disabled={loading}
										value={businessProfileName}
										variant="outlined"
										onChange={(event: any) => {
											setBusinessProfileName(event.target.value);
										}}
										sx={{
											width: "100%",
											marginTop: "1.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
									/>

									<FormControl
										variant="outlined"
										fullWidth
										sx={{
											marginTop: "1rem",
											borderRadius: "0.75rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
									>
										<InputLabel
											id="profile-type"
											sx={{ padding: "0rem" }}
										>
											Business profile type *
										</InputLabel>
										<Select
											labelId="profile-type"
											label="Business profile type *"
											required={true}
											disabled={loading}
											value={businessProfileType}
											sx={{
												width: "100%",
												borderRadius: "0.75rem",
												"& .MuiOutlinedInput-root": {
													borderRadius: "0.75rem",
												},
											}}
											onChange={(e) => {
												setBusinessProfileType(e.target.value);
											}}
										>
											<MenuItem value="agent">Agent</MenuItem>
											<MenuItem value="developer">Developer</MenuItem>
											<MenuItem value="landowner">Landowner</MenuItem>
										</Select>
									</FormControl>
								</TextContainer>
							) : null}

							<TextContainer sx={{ gap: "1rem" }}>
								<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
									<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
										Enter the name of your property
									</Typography>
									<Tooltip title="	Once created, you cannot change the property name">
										<InfoOutlined
											sx={{
												color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
												height: "1.25rem",
												width: "1.25rem",
											}}
										/>
									</Tooltip>
								</TextContainer>
								<TextField
									label="Property Name *"
									placeholder="Enter property name"
									required={true}
									disabled={
										loading ||
										(businessProfiles === undefined && (businessProfileName === "" || businessProfileType === ""))
									}
									value={propertyTitle}
									variant="outlined"
									onChange={(event: any) => {
										setPropertyTitle(event.target.value);
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
								/>
								{businessProfiles === undefined ? null : (
									<Typography
										sx={{
											fontSize: "1rem",
											fontWeight: 400,
											lineHeight: "150%",
										}}
									>
										Posting as : {businessProfiles === undefined ? businessProfileName : businessProfiles?.page_title}
									</Typography>
								)}
							</TextContainer>
						</PropertyNameContainer>
					</CustomDiv>
				);
			case 1:
				return (
					<PropertyLocation
						dispatch={dispatch}
						newPropertyDataLocationCoordinates={newPropertyData?.search_metrics?.location_coordinates}
					/>
				);
			case 2:
				return (
					<PropertyDetails
						property_transaction_type={newPropertyData?.transaction_type}
						property_category_type={newPropertyData?.property_category}
						property_type={newPropertyData?.property_type}
						property_subtype={newPropertyData?.property_subtype}
						property_min_price={newPropertyData?.price?.min}
						property_max_price={newPropertyData?.price?.max}
						property_security_deposit={newPropertyData?.security_deposit as security_deposit}
						property_availability={newPropertyData?.availability}
						property_possession_date={newPropertyData?.possession_date}
						property_unit_facing={
							newPropertyData?.unit_facing
								? newPropertyData?.unit_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_land_facing={
							newPropertyData?.land_facing
								? newPropertyData?.land_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_age={newPropertyData?.property_age}
						dispatch={dispatch}
					/>
				);
			case 3:
				return (
					<PropertyConfig
						propertyData={newPropertyData}
						property_furnishing_status={newPropertyData?.furnished as string[]}
						property_plot_dimensions={newPropertyData?.plot_dimensions ? newPropertyData?.plot_dimensions : ["30x40"]}
						property_area={newPropertyData?.area as Area}
						property_bhk={newPropertyData?.bhk as BHK}
						property_bathroom={newPropertyData?.bathroom as BHK}
						property_balconies={newPropertyData?.balconies as BHK}
						property_floors={newPropertyData?.floors as BHK}
						property_floor_no={newPropertyData?.floor_no as BHK}
						property_no_of_units={newPropertyData?.no_of_units as BHK}
						property_no_of_seats={newPropertyData?.no_of_seats as BHK}
						studio={studio}
						dispatch={dispatch}
						session={session}
					/>
				);
			case 4:
				return (
					<PropertyOverview
						overview_data={newPropertyData?.description}
						dispatch={dispatch}
					/>
				);
			case 5:
				return (
					<UploadImages
						newPropertyData={newPropertyData}
						dispatch={dispatch}
						session={session}
						businessProfiles={businessProfiles}
						setImgPreview={setImgPreview}
						isBusinessProfile={true}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 6:
				return (
					<PropertyCard
						images={imgPreview}
						title={propertyTitle}
						property_status={newPropertyData?.availability}
						transaction_type={newPropertyData?.transaction_type}
						price={newPropertyData?.price}
						property_type={newPropertyData?.property_type}
						location={newPropertyData?.location.title}
						area={newPropertyData?.area}
						bhk={newPropertyData?.bhk}
						price_per_unit={newPropertyData?.price_per_unit}
						floors={newPropertyData?.floors}
						no_of_seats={newPropertyData?.no_of_seats}
						possession_date={newPropertyData?.possession_date as string}
					/>
				);
		}
	};
	return (
		<React.Fragment>
			{/* alert dialog */}
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newPropertyData?.snack_text === "Error while creating Property" ? "error" : "success"}
					sx={{ width: "100%" }}
				>
					{newPropertyData?.snack_text}
				</Alert>
			</Snack>
			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>
					Close property upload ?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>All the unsaved data will be removed. Are you sure ?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="text"
					>
						No
					</BeegruButton>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
					>
						Yes
					</BeegruButton>
				</DialogActions>
			</Dialog>
			<CustomDialog
				open={open}
				maxWidth="sm"
				fullWidth={true}
				PaperProps={{
					style: {
						maxHeight: 670,
					},
				}}
				sx={{ "&::-webkit-scrollbar": { display: "none" } }}
				disableScrollLock={false}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						{!loading && createBusinessProfile && businessProfilesCount > 0 ? (
							<IconButton
								aria-label="back"
								color="primary"
								sx={{
									padding: "0rem 0.5rem 0rem 0rem",
								}}
								onClick={() => {
									setcreateBusinessProfile(false);
								}}
							>
								<ArrowBack />
							</IconButton>
						) : null}
						{activeStep === 0
							? "Create New Property"
							: activeStep === 1
								? "Property Location"
								: activeStep === 2
									? "Property Details"
									: activeStep === 3
										? "Property Configuration"
										: activeStep === 4
											? "Add Overview"
											: activeStep === 5
												? "Upload Images"
												: "Property Preview"}
						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
							}}
						>
							{loading ? null : <Close />}
						</IconButton>
					</TextContainer>
					<ColorLinearProgress
						variant="determinate"
						value={(activeStep / 6) * 100}
						sx={{ width: "100%" }}
					/>
				</CustomDialogTitle>

				<CustomDialogContent>
					{/* Profile Select */}
					{stepperContent(activeStep)}
					{activeStep === 2 || activeStep === 3 ? (
						<Typography
							sx={{
								fontSize: "1rem",
								fontWeight: 400,
								lineHeight: "150%",
								color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
							}}
						>
							Fields marked * are mandatory fields.
						</Typography>
					) : null}
					<CustomDiv>
						{!loadNext ? (
							<TextContainer
								sx={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "1rem",
								}}
							>
								{activeStep === 0 ? (
									<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
								) : (
									<BeegruButton
										size="medium"
										flavor="primary"
										variant="text"
										onClick={handleBack}
										disabled={activeStep === 0 || loadNext}
										sx={{ width: "8.75rem" }}
									>
										Back
									</BeegruButton>
								)}

								<StepDiv>
									<CustomTypography>{activeStep + 1}/7</CustomTypography>
								</StepDiv>

								<BeegruButton
									size="medium"
									flavor="primary"
									variant="contained"
									onClick={activeStep === 6 ? handleClickCreateNewProperty : handleNext}
									disabled={stepZeroComplete || stepOneComplete || stepTwoComplete || stepThreeCompelte}
									sx={{ width: "8.75rem" }}
								>
									{activeStep === 5 ? "Preview" : activeStep === 6 ? "Publish" : "Next"}
								</BeegruButton>
							</TextContainer>
						) : (
							<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
								<Typography
									variant="body2"
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										gap: "0.25rem",
										color: "primary.main",
									}}
								>
									Creating property<Circle>.</Circle>
									<Circle1>.</Circle1>
									<Circle2>.</Circle2>
								</Typography>
								<CircularProgress size={24} />
							</TextContainer>
						)}
					</CustomDiv>
				</CustomDialogContent>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreatePropertyWithUploadTool;
