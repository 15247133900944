/* Redux Imports */

import { Area } from "../redux-magic/sub-interfaces/sub-interfaces";
import NumberToCurrencyPipe from "./NumberToCurrencyPipe";

const prepareAreaString = (area: Area, areaType: string) => {
	if (areaType === "land_area") {
		const acreValue = Number(area?.land_area?.max?.acre) ?? 0;
		const formattedAcreValue = acreValue % 1 === 0 ? acreValue.toFixed(0) : acreValue.toFixed(2);
		return `${formattedAcreValue} acre`;
	} else if (areaType === "super_built_up_area") {
		if (
			area !== undefined &&
			area.super_built_up_area !== undefined &&
			area.super_built_up_area?.min.ft === area.super_built_up_area?.max.ft
		) {
			return (
				(area
					? area.super_built_up_area
						? area.super_built_up_area.max
							? area.super_built_up_area.max.ft
								? NumberToCurrencyPipe(area.super_built_up_area.max.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) + " sqft "
			);
		} else {
			return (
				(area
					? area.super_built_up_area
						? area.super_built_up_area.min
							? area.super_built_up_area.min.ft
								? NumberToCurrencyPipe(area.super_built_up_area.min.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) +
				"-" +
				(area
					? area.super_built_up_area
						? area.super_built_up_area.max
							? area.super_built_up_area.max.ft
								? NumberToCurrencyPipe(area.super_built_up_area.max.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) +
				" sqft "
			);
		}
	} else if (areaType === "carpet_area") {
		if (area !== undefined && area.carpet_area !== undefined && area.carpet_area.min.ft === area.carpet_area.max.ft) {
			return (
				(area
					? area.carpet_area
						? area.carpet_area.max
							? area.carpet_area.max.ft
								? NumberToCurrencyPipe(area.carpet_area.max.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) + " sqft "
			);
		} else {
			return (
				(area
					? area.carpet_area
						? area.carpet_area.min
							? area.carpet_area.min.ft
								? NumberToCurrencyPipe(area.carpet_area.min.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) +
				"-" +
				(area
					? area.carpet_area
						? area.carpet_area.max
							? area.carpet_area.max.ft
								? NumberToCurrencyPipe(area.carpet_area.max.ft.toFixed(0))
								: 0
							: 0
						: 0
					: 0) +
				" sqft "
			);
		}
	}
};

export default prepareAreaString;
