/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { Price } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, Skeleton } from "@mui/material";

/* Library Imports */

import PreparePriceString from "../../../../lib/PreparePriceString";
import { CurrencyRupee } from "@mui/icons-material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "1.25rem",
	lineHeight: "1.5rem",
	letterSpacing: "0.009rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0.15rem 0rem 0rem 0rem",
	fontSize: "1.25rem",
}));

const GridCardPrice = ({ price }: { price: Price }) => {
	
	return (
		<React.Fragment>
			<Heading>
				{/* <InfoIcon
					src={
						theme.palette.mode === "dark"
							? "/images/icons/config/currency_rupee_white.svg"
							: "/images/icons/config/currency_rupee.svg"
					}
					alt="Price Icon"
					width={17}
					height={24}
					loading="lazy"
					referrerPolicy="no-referrer"
				/> */}
				<RupeeIcon />
				{price !== undefined
					? price
						? price.min === price.max
							? PreparePriceString(price.max)
							: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
						: "Price not available"
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardPrice;
