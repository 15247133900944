/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import { UserInterface } from "../../../../../redux-magic/sub-interfaces/user-interface";
import { BusinessProfileInterface } from "../../../../../redux-magic/sub-interfaces/business-profile-interface";
import { StoreLeadsThunk } from "../../../../../redux-magic/store";
import { ObjectId } from "bson";

/* Component Imports */

import { Typography, Skeleton, Snackbar, useTheme } from "@mui/material";
import BeegruButton from "../../../../common-components/buttons/BeegruButton";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
			sx={{
				zindex: 8,
			}}
		/>
	);
});

/* Icon Imports */

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

/* Styled Components */

const Snack = styled(Snackbar)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "auto",
	margin: "2.5rem 0rem 0rem 0rem",
	zindex: 8,
	".&MuiSnackbar-root": {
		top: "0px",
		zindex: 8,
	},
}));

// const Heading2 = styled(Typography)(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "column",
// 	justifyContent: "flex-start",
// 	alignItems: "flex-start",
// 	width: "100%",
// 	margin: "0rem 0.2rem 0rem 0rem",
// }));

// const DeveloperLink = styled(Link)(({ theme }) => ({
// 	textDecoration: "none",
// 	color: theme.palette.mode == "dark" ? "#fff" : "#000",
// 	"&:hover": {
// 		textDecoration: "none",
// 	},
// }));

const OFBCardConfigAndContact = ({}: {}) => {
	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "100%",
					margin: "0rem 0rem 0rem 0rem",
				}}
			>
				<BeegruButton
					variant="outlined"
					color="primary"
					sx={{
						fontSize: "0.875rem",
						lineHeight: "1rem",
						letterSpacing: "0.025rem",
						fontWeight: 400,
						width: "100%",
						height: "100%",
						padding: "0.5rem 0.5rem 0.5rem 0.5rem",
					}}
					onClick={(event: any) => {}}
				>
					Get a callback
				</BeegruButton>
			</div>
		</React.Fragment>
	);
};

export default OFBCardConfigAndContact;
